import axios from "axios";
import {CsrfToken} from "../model/Model";
import {Toast} from "primereact/toast";
import moment from "moment-timezone";

const prod = {
	url: {
		UI_URL: "/",
		API_URL: "/api/"
	},
	signInDefaults: {
		username: "",
		password: ""
	},
	withCredentials: false
};

const dev = {
	url: {
		UI_URL: "http://localhost:3000/",
		API_URL: "http://localhost:8080/api/"
	},
	signInDefaults: {
		username: "a.dministrator@acme.com",
		password: "adminadminadmin"
	},
	withCredentials: true
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

// ensure that cookies are send with cors (local development only)
axios.defaults.withCredentials = config.withCredentials;

// on a network error, redirect to the logon page
axios.interceptors.response.use(response => response, error => {
	console.log(error)
	if (error?.response?.status !== undefined && error?.response?.status === 403 && !error.request.responseURL.endsWith('/sign-in')) {
		document.location = "/sign-in?redirect_url=" + document.location.href
	} else
		return Promise.reject(error)
})

export const withCsrf = (): Promise<CsrfToken> => axios
	.get<CsrfToken>(config.url.API_URL + "csrf")
	.then(response => response.data)

export function showReasonOnToast(reason: any, toast: React.RefObject<Toast>) {
	if (reason.response.status === 406) {
		toast.current!.show({
			severity: "error",
			summary: "An error occurred",
			detail: reason.response.data.message
		})
	} else {
		throw reason
	}
}

export function formatDate(date: Date | null): string {
	if (date === null)
		return '';

	return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
}

console.log("Configuring the default timezone to " + Intl.DateTimeFormat().resolvedOptions().timeZone)
moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone)
