import {Nullable} from "primereact/ts-helpers";

export default interface UserDTO {
	id: string | null,
	firstName: string,
	lastName: string,
	email: string,
	mobile: string,
	administrator: boolean,
	language: string,
}

export interface DeviceDTO {
	id: string | null,
	source: string,
	type: string,
	externalId: string,
	externalName: string,
	sensorSerial: number | null,
	name: string,
	mostRecentSyncTimestamp: string,
	outlierDetectionEnabled: boolean,
}

export interface CalibrationDTO {
	id: string | null,
	deviceId: string | null,
	deviceName: string | null,
	phSevenValueMv: number | null,
	phSlopeMvPh: number | null,
	ecKCell: number | null,
	timestamp: Nullable<Date>,
}

export interface AccessDTO {
	id: string | null,
	user: string,
	userName: string,
	device: string,
	deviceName: string,
	mobileAlert: boolean,
	mailAlert: boolean,
}

export interface SignInDTO {
	username: string,
	password: string,
	redirectUrl: string | null,
	_csrf: string,
}

export interface ForgotPasswordDTO {
	email: string,
}

export interface ResetPasswordDTO {
	password: string,
	verifyPassword: string
}

export interface ProfileDTO {
	roles: Role[]
}

export enum Role {
	USER = "USER",
	ADMINISTRATOR = "ADMINISTRATOR"
}

export interface DropdownDTO {
	value: string,
	label: string
}

export interface CsrfToken {
	token: string,
	headerName: string
}

export interface ValidationStatus {
	issues: Record<string, string>;
}
