import React, {useEffect, useRef, useState} from 'react';
import {Column} from "primereact/column";
import axios from "axios";
import {config, withCsrf} from "../util/consts";
import {Button} from "primereact/button";
import {CalibrationDTO, DropdownDTO} from "../model/Model";
import {Toast} from "primereact/toast";
import LiquisensDataTable from "../components/LiquisensDataTable";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import moment from "moment-timezone";

export default function CalibrationPage() {

	const toast = useRef<Toast>(null);
	const [calibrations, setCalibrations] = useState<CalibrationDTO[]>();
	const [selectedCalibration, setSelectedCalibration] = useState<CalibrationDTO>({
		id: null,
		deviceId: null,
		deviceName: "",
		phSevenValueMv: null,
		phSlopeMvPh: null,
		ecKCell: null,
		timestamp: null,
	});
	const [devices, setDevices] = useState<DropdownDTO[]>();
	const [visible, setVisible] = useState<boolean>(false);

	const loadCalibrations = () => {
		axios.get<CalibrationDTO[]>(config.url.API_URL + "calibration")
			.then(response => setCalibrations(response.data))
	}

	const loadDropdowns = () => {
		axios.get<DropdownDTO[]>(config.url.API_URL + "device/dropdown/LIQUISENS_MULTI_PARAMETER_SENSOR")
			.then(response => setDevices(response.data))
	};

	const loadCalibration = (id: string | null) => {
		axios.get<CalibrationDTO>(config.url.API_URL + "calibration/" + id)
			.then(response => {
				setSelectedCalibration({
					...response.data,
					timestamp: new Date(Date.parse(response.data.timestamp!.toString()))
				})
			})
	}

	useEffect(() => {
			loadCalibrations()
			loadDropdowns()
		}, []
	)

	const addCalibration = () => {
		setVisible(true);
		setSelectedCalibration({
			id: null,
			deviceId: null,
			deviceName: "",
			phSevenValueMv: null,
			phSlopeMvPh: null,
			ecKCell: null,
			timestamp: null,
		})
	}

	const editCalibration = (calibration: CalibrationDTO) => {
		loadCalibration(calibration.id);
		setVisible(true);
	}

	const deleteCalibration = (calibration: CalibrationDTO) => {
		withCsrf()
			.then(csrf => {
					axios.delete<void>(config.url.API_URL + "calibration/" + calibration.id, {
						headers: {
							'X-CSRF-TOKEN': csrf.token
						}
					})
						.then(response => {
								loadCalibrations()
								toast.current!.show({
									severity: "info",
									summary: "Calibration deleted",
									detail: "The calibration has been deleted from the platform. Recalculation on-going."
								});
							}
						)
				}
			)
	}

	const saveCalibration = () => {
		withCsrf()
			.then(csrf => {

				axios.post<CalibrationDTO>(config.url.API_URL + "calibration", selectedCalibration, {
					headers: {
						'X-CSRF-TOKEN': csrf.token
					}
				}).then(response => {
					loadCalibrations()
					setVisible(false)
					toast.current!.show({
						severity: "info",
						summary: "Calibration saved",
						detail: "The calibration has been saved to the database. Recalculation on-going."
					})
				});
			});
	}

	const actionButtons = (calibration: CalibrationDTO) => {
		return (
			<>
				<div className="flex flex-row gap-2">
					<Button icon="pi pi-trash" severity="danger" size="small" tooltip="Delete"
							onClick={() => deleteCalibration(calibration)}/>
					<Button icon="pi pi-pencil" severity="secondary" size="small" tooltip="Edit"
							onClick={() => editCalibration(calibration)}
					/>
				</div>
			</>
		);
	};

	const formatDateTimestamp = (calibration: CalibrationDTO) => {
		return calibration.timestamp === null
			? "-"
			: moment(calibration.timestamp).format('DD/MM/YYYY HH:mm [(GMT]ZZ[)]');
	}

	return (
		<>
			<Toast ref={toast}/>
			<div className="flex">
				<div className="flex-1">
					<h1>Calibrations</h1>
				</div>
				<div className="flex-1">
					<div className="flex justify-content-end align-self-center">
						<Button icon="pi pi-plus" label="Add calibration" onClick={() => addCalibration()}
								size="small"/>
					</div>
				</div>
			</div>
			<div>
				<LiquisensDataTable
					value={calibrations}
					selection={selectedCalibration}
				>
					<Column field="deviceName" header="Device" sortable></Column>
					<Column field="timestamp" header="Timestamp" body={formatDateTimestamp} sortable></Column>
					<Column field="phSevenValueMv" header="pH 7 mv" sortable></Column>
					<Column field="phSlopeMvPh" header="pH Slope mv/pH" sortable></Column>
					<Column field="ecKCell" header="ec K Cell" sortable></Column>
					<Column body={actionButtons} exportable={false} style={{width: '50px'}}></Column>
				</LiquisensDataTable>
			</div>
			<Dialog header="Calibration" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}>
				<div className="modal">
					<div className="card flex">
						<div className="flex flex-column gap-2 flex-1">

							<label htmlFor="device">Device</label>
							<Dropdown options={devices} value={selectedCalibration.deviceId}
									  placeholder="Select a Device"
									  onChange={e =>
										  setSelectedCalibration({
											  ...selectedCalibration,
											  deviceId: e.value
										  })}
							/>
							<label htmlFor="timestamp">Timestamp</label>
							<Calendar id="timestamp" value={selectedCalibration.timestamp} showTime hourFormat="24"
									  dateFormat="dd/mm/yy"
									  onChange={e => setSelectedCalibration({
										  ...selectedCalibration,
										  timestamp: e.target.value
									  })}/>
							<label htmlFor="phSevenValueMv">pH 7 mv</label>
							<InputNumber id="phSevenValueMv" value={selectedCalibration.phSevenValueMv}
										 minFractionDigits={0} maxFractionDigits={5}
										 onChange={e => setSelectedCalibration({
											 ...selectedCalibration,
											 phSevenValueMv: e.value
										 })}/>
							<label htmlFor="phSlopeMvPh">pH Slope mv/pH</label>
							<InputNumber id="phSlopeMvPh" value={selectedCalibration.phSlopeMvPh} minFractionDigits={0}
										 maxFractionDigits={5}
										 onChange={e => setSelectedCalibration({
											 ...selectedCalibration,
											 phSlopeMvPh: e.value
										 })}/>
							<label htmlFor="ecKCell">ec K Cell</label>
							<InputNumber id="ecKCell" value={selectedCalibration.ecKCell} minFractionDigits={0}
										 maxFractionDigits={5}
										 onChange={e => setSelectedCalibration({
											 ...selectedCalibration,
											 ecKCell: e.value
										 })}/>

							<div className="flex flex-row gap-2">
								<div className="flex-1">
									<Button label="Cancel" severity="secondary" onClick={() => setVisible(false)}/>
								</div>
								<div className="flex-1">
									<div className="flex justify-content-end align-self-center gap-2">
										<Button label="Submit" icon="pi pi-save" onClick={saveCalibration}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
}
