import React, {useEffect, useRef, useState} from 'react';
import {Column} from "primereact/column";
import axios from "axios";
import {config, withCsrf} from "../util/consts";
import {Button} from "primereact/button";
import {DeviceDTO, ProfileDTO, Role} from "../model/Model";
import {Toast} from "primereact/toast";
import LiquisensDataTable from "../components/LiquisensDataTable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Checkbox} from "primereact/checkbox";
import moment from "moment-timezone";

export default function DevicePage() {

	const toast = useRef<Toast>(null);
	const [profile, setProfile] = useState<ProfileDTO>();
	const [devices, setDevices] = useState<DeviceDTO[]>();
	const [selectedDevice, setSelectedDevice] = useState<DeviceDTO>({
		id: null,
		source: "",
		type: "",
		externalId: "",
		externalName: "",
		sensorSerial: null,
		name: "",
		mostRecentSyncTimestamp: "",
		outlierDetectionEnabled: false,
	});
	const [visible, setVisible] = useState<boolean>(false);

	const loadProfile = () => {
		axios.get<ProfileDTO>(config.url.API_URL + "menu/profile")
			.then(response => setProfile(response.data))
	}

	const loadDevices = () => {
		axios.get<DeviceDTO[]>(config.url.API_URL + "device")
			.then(response => setDevices(response.data))
	}

	const loadDevice = (id: string | null) => {
		axios.get<DeviceDTO>(config.url.API_URL + "device/" + id)
			.then(response => {
				setSelectedDevice(response.data)
			})
	}

	useEffect(() => {
			loadDevices()
			loadProfile()
		}, []
	)

	const editDevice = (device: DeviceDTO) => {
		loadDevice(device.id);
		setVisible(true);
	}

	const saveDevice = () => {
		withCsrf()
			.then(csrf => {
				axios.post<DeviceDTO>(config.url.API_URL + "device", {
					id: selectedDevice.id,
					name: selectedDevice.name,
					outlierDetectionEnabled: selectedDevice.outlierDetectionEnabled,
				}, {
					headers: {
						'X-CSRF-TOKEN': csrf.token
					}
				}).then(response => {
					loadDevices()
					setVisible(false)
					toast.current!.show({
						severity: "info",
						summary: "Device saved",
						detail: "The device has been saved to the database."
					})
				});
			});
	}

	const actionButtons = (device: DeviceDTO) => {
		return (
			<>
				<div className="flex flex-row gap-2">
					<Button icon="pi pi-chart-line" severity="secondary" size="small" tooltip="Edit"
							visible={profile?.roles.includes(Role.ADMINISTRATOR)}
							onClick={() => document.location = "/graph/" + device.id}
					/>
					<Button icon="pi pi-pencil" severity="secondary" size="small" tooltip="Edit"
							visible={profile?.roles.includes(Role.ADMINISTRATOR)}
							onClick={() => editDevice(device)}
					/>
				</div>
			</>
		);
	};

	const formatDateTimestamp = (device: DeviceDTO) => {
		return device.mostRecentSyncTimestamp === null
			? "-"
			: moment(device.mostRecentSyncTimestamp).format('DD/MM/YYYY HH:mm [(GMT]ZZ[)]');
	}

	const outlierDetectionEnabledFlagTemplate = (device: DeviceDTO) => {
		return <i className={classNames('pi', {
			'true-icon pi-check-circle': device.outlierDetectionEnabled,
			'false-icon pi-times-circle': !device.outlierDetectionEnabled
		})}></i>;
	};


	return (
		<>
			<Toast ref={toast}/>
			<div className="flex">
				<div className="flex-1">
					<h1>Devices</h1>
				</div>
			</div>
			<div>
				<LiquisensDataTable
					value={devices}
					selection={selectedDevice}
				>
					<Column field="source" header="Source" sortable></Column>
					<Column field="type" header="Type" sortable></Column>
					<Column field="externalId" header="External id" sortable></Column>
					<Column field="sensorSerial" header="Serial" sortable></Column>
					<Column field="externalName" header="External name" sortable></Column>
					<Column field="name" header="Device name" sortable></Column>
					<Column field="mostRecentSyncTimestamp" body={formatDateTimestamp}
							header="Last Synchronisation Timestamp" sortable></Column>
					<Column field="outlierDetectionEnabled" header="Outlier Detection Enabled"
							body={outlierDetectionEnabledFlagTemplate} sortable></Column>
					<Column body={actionButtons} exportable={false} style={{width: '50px'}}></Column>
				</LiquisensDataTable>
			</div>
			<Dialog header="Device" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}>
				<div className="modal">
					<div className="card flex">
						<div className="flex flex-column gap-2 flex-1">

							<label htmlFor="source">Source</label>
							<InputText id="source" value={selectedDevice.source} readOnly disabled/>
							<label htmlFor="type">Type</label>
							<InputText id="type" value={selectedDevice.type} readOnly disabled/>
							<label htmlFor="externalId">External id</label>
							<InputText id="externalId" value={selectedDevice.externalId} disabled/>
							<label htmlFor="externalName">External name</label>
							<InputText id="externalName" value={selectedDevice.externalName} disabled/>
							<label htmlFor="name">Name</label>
							<InputText id="name" value={selectedDevice.name} onChange={e => setSelectedDevice({
								...selectedDevice,
								name: e.target.value
							})}/>
							<label htmlFor="outlierDetectionEnabled">Outlier detection enabled</label>
							<Checkbox id="outlierDetectionEnabled" checked={selectedDevice.outlierDetectionEnabled}
									  onChange={e => setSelectedDevice({
										  ...selectedDevice,
										  outlierDetectionEnabled: e.target.checked!
									  })}/>

							<div className="flex flex-row gap-2">
								<div className="flex-1">
									<Button label="Cancel" severity="secondary" onClick={() => setVisible(false)}/>
								</div>
								<div className="flex-1">
									<div className="flex justify-content-end align-self-center gap-2">
										<Button label="Submit" icon="pi pi-save" onClick={saveDevice}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
}
